<template>
  <section class="new_css">
    <el-row class="new_header">
      <el-col
        v-if="buttonList.length > 0"
        :span="24"
        class="organizetoolbar"
        style="padding-bottom: 0px"
      >
        <el-form
          :inline="true"
          ref="selectForm"
          :model="selectForm"
          class="select_form"
        >
          <el-form-item label="用户名:">
            <el-input
              v-model="selectForm.userName"
              placeholder="用户名"
            ></el-input>
          </el-form-item>
          
          <toolbar
            :buttonList="buttonList"
            @callFunction="callFunction"
            :buttonListmsg="buttonListmsg"
          ></toolbar>
        </el-form>
      </el-col>
    </el-row>
    <div class="calc-height">
      <el-table
        :data="users"
        highlight-current-row
     
        height="100%"
        @current-change="selectCurrentRow"
        @row-dblclick="handleShow"
        style="width: 100%; margin-top: 15px"
      >
        <el-table-column prop="UserInfoUserName" label="姓名">
        </el-table-column>
        <el-table-column prop="Post" label="岗位名称"> </el-table-column>
        <el-table-column
          label="转正日期"
          prop="StartDate"
          :formatter="formatStartTime"
        ></el-table-column>
        <el-table-column
          label="转正工资执行日期"
          prop="SalaryDate"
          :formatter="formatEndTime"
        ></el-table-column>
        <el-table-column
          label="薪资类型"
          prop="DicSalaryTypeType"
        ></el-table-column>
        <el-table-column
          label="职务待遇"
          prop="DicPositionLeaveName"
        ></el-table-column>
      </el-table>
    </div>
    <!-- 分页器 -->
    <el-pagination
      align="center"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-size="pageSize"
      layout="prev, pager, next, jumper,total"
      :page-count="total"
      :total="totaldata"
    >
    </el-pagination>

    <el-dialog
      :title="operation ? '修改' : '查看'"
      :visible.sync="permissionShowVisible"
      v-model="permissionShowVisible"
      :close-on-click-modal="false"
    >
      <el-form
        label-width="120px"
        :rules="postRules"
        ref="PermissionForm"
        :model="PermissionForm"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="岗位:" prop="Post">
              <el-input
                type="text"
                :disabled="true"
                v-model="PermissionForm.Post"
              >
                <el-button
                  slot="append"
                  icon="el-icon-search"
                  @click="chooseUser"
                ></el-button>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="伙食日单价:" prop="EateDaySalary">
              <el-select
                v-model="PermissionForm.EateDaySalary"
                placeholder="伙食日单价"
                style="“width: 100%”"
              >
                <el-option
                  v-for="item in EateSalaryLists"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="12">
            <el-form-item prop="StartDate" label="转正日期:">
              <el-date-picker
                type="date"
                v-model="PermissionForm.StartDate"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
                style="“width: 100%”"
              ></el-date-picker>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="转正工资执行时间:" prop="salaryDate">
              <el-date-picker
                type="month"
                v-model="PermissionForm.SalaryDate"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
                style="“width: 100%”"
              ></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item
              label="是否有年功:"
              prop="IsYear"
              :rules="[
                { required: true, message: '不能为空', trigger: 'blur' },
              ]"
            >
              <el-select
                v-model="PermissionForm.IsYear"
                placeholder="是否有年功"
                style="width: 100%"
              >
                <el-option label="是" value="是"></el-option>
                <el-option label="否" value="否"></el-option>
                <el-option label="超龄年功" value="超龄年功"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="超龄年功工资:" prop="OverAgeUserAvailable">
              <el-input
                type="text"
                v-model="PermissionForm.OverAgeUserAvailable"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="12">
            <el-form-item label="日保健费:">
              <el-select
                v-model="PermissionForm.CareDaySalary"
                placeholder="日保健费"
                style="width: 100%"
              >
                <el-option
                  v-for="item in CareDaySalaryLists"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="驻外补贴:">
              <el-select
                v-model="PermissionForm.OutsideSalary"
                placeholder="驻外补贴"
                style="width: 100%"
              >
                <el-option
                  v-for="item in OutsideSalaryLists"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="值班日保健费:" prop="OndutyCareSalary">
              <el-input
                type="text"
                v-model="PermissionForm.OndutyCareSalary"
                @blur="inputMoney('OndutyCareSalary')"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="工人系数:" prop="WorkerMod">
              <el-input
                type="text"
                v-model="PermissionForm.WorkerMod"
                @blur="inputMoney('WorkerMod')"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="租房补贴:" prop="HouseSalary">
              <el-input
                type="text"
                v-model="PermissionForm.HouseSalary"
                @blur="inputMoney('HouseSalary')"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="绩效工资:" prop="PerfSalary">
              <el-input
                type="text"
                v-model="PermissionForm.PerfSalary"
                @blur="inputMoney('PerfSalary')"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="特岗补贴:">
              <el-input v-model="PermissionForm.SpecialSalary"></el-input>
            </el-form-item>
          </el-col>

          <!-- <el-col :span="12">
      <el-form-item  label="转正待遇:">
			  <el-input  v-model="PermissionForm.PositiveExplain"></el-input>
	</el-form-item>
   </el-col>		   -->
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="车辆补贴:">
              <el-input v-model="PermissionForm.BusMoney"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="岗位工资:" prop="WorkSalary">
              <el-input v-model="PermissionForm.WorkSalary"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="爱心基金:">
              <el-input v-model="PermissionForm.LoveMoney"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="日工资:">
              <el-input v-model="PermissionForm.DaySalary"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="银行:">
              <el-input v-model="PermissionForm.BankCardName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="银行卡号:">
              <el-input v-model="PermissionForm.BankCardCD"></el-input>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="12">
      <el-form-item  label="开户行:">
			  <el-input  v-model="PermissionForm.BankAddress"></el-input>
	</el-form-item>
   </el-col>		   -->
        </el-row>

        <el-row>
          <el-col :span="12">
            <el-form-item label="薪资类型:" prop="SalaryType">
              <el-select
                style="width: 100%"
                v-model="PermissionForm.SalaryType"
                @change="SalaryTypeModel($event)"
                placeholder="薪资类型"
              >
                <el-option
                  v-for="item in SalaryTypeLists"
                  :key="item.Id"
                  :label="item.Type"
                  :value="item.Type"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="职位待遇:" prop="PostSalaryName">
              <el-select
                style="width: 100%"
                v-model="PermissionForm.PostSalaryName"
                @change="PositionModel($event)"
                placeholder="职位待遇"
              >
                <el-option
                  v-for="item in PositionLists"
                  :key="item.Id"
                  :label="item.LeaveName"
                  :value="item.LeaveName"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <upload-files
          :files="PermissionForm.FileArry"
          :key="key"
          action="/cyl/ftp/ftp/upload"
          :limit="20"
          @fun="dealFiles"
        ></upload-files>
        <el-row v-if="operation">
          <el-col :span="24" style="text-align: center">
          
              <el-button
                type="primary"
                v-on:click="onPermissionSubmit('PermissionForm')"
                >提交</el-button
              >
           
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
  </section>
</template>
<script>
import util from "../../../util/date";
import * as validate from "../../../util/validate";
import {
  GetPositivePageList,
  getInfoList,
  GetUserDis,
  UpdatePositive,
        getGetPositiveDetail,
        DeletePositiveInfo
} from "../../api/hr";
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/newToolbar";
import UploadFiles from "../../components/UploadFiles";
export default {
  components: { Toolbar, UploadFiles },
  data() {
    return {
      loading: true,
      currentPage: 1, // 当前页码
      total: null, // 总条数
      totaldata: null,
      pageSize: 20, // 每页的数据条数
      page: 1,
      key: 0,
      searchVal: "",
      filters: {
        name: "",
      },
      selectForm: {
        postName: "",
        startDate: "",
        endDate: "",
        salaryType: "",
        positionSal: "",
        userName: "",
      },
      operation: false,
      PermissionShowForm: [],
      addUserVisible: false,
      PositionLists: [],
      SalaryTypeLists: [],
      choosedusers: [],
      buttonListmsg: "",
      interviewVisible: false,
      userDetailVisible: false,
      users: [],
      buttonList: [],
      permissionVisible: false,
      permissionShowVisible: false,
      PermissionForm: [],
      EateSalaryLists: [
        {
          value: "0",
          label: "0",
        },
        {
          value: "5",
          label: "5",
        },
        {
          value: "10",
          label: "10",
        },
        {
          value: "25",
          label: "25",
        },
        {
          value: "30",
          label: "30",
        },
      ],
      CareDaySalaryLists: [
        {
          value: "5",
          label: "5",
        },
        {
          value: "10",
          label: "10",
        },
      ],
      OutsideSalaryLists: [
        {
          value: "300",
          label: "300",
        },
        {
          value: "500",
          label: "500",
        },
      ],
      ProbationExplainLists: [
        {
          value: "1",
          label: "1",
        },
        {
          value: "2",
          label: "2",
        },
        {
          value: "3",
          label: "3",
        },
        {
          value: "5",
          label: "5",
        },
      ],
      postRules: {
        Post: [{ required: true, message: "请选择岗位", trigger: "blur" }],
        StartDate: [
          { required: true, message: "请选择开始时间", trigger: "blur" },
        ],
        DicPositionLeaveName: [
          { required: true, message: "请选择职务待遇", trigger: "blur" },
        ],
        DicSalaryTypeType: [
          { required: true, message: "请选择工资类型", trigger: "blur" },
        ],
        SalaryDate: [
          {
            required: true,
            message: "请选择转正工资执行日期",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    selectCurrentRow(val) {
      this.currentRow = val;
    },
    callFunction(item) {
      this[item.Func].apply(this, item);
    },
    //获取用户列表
    getData(isCurrent = true) {
      if (isCurrent) {
        this.page = 1;
      }
      let para = {
        page: this.page,
        size: this.pageSize,
        userName: this.selectForm.userName,
      };

      this.$api.PositiveGetSubmitInfoPageList(para).then((res) => {
        this.total = res.data.response.pageCount;
        this.totaldata = res.data.response.dataCount;
        this.users = res.data.response.data;
        this.loading = false;
      });
    },
    nputMoney(name) {
      if (!validate.validateIsMoney(this.PermissionForm[name])) {
        this.$message({
          message: "请输入金额",
          type: "error",
          duration: 5000,
        });
        this.PermissionForm[name] = null;
        return;
      }
    },
    getButtonList2(routers) {
      let _this = this;
      routers.forEach((element) => {
        let path = this.$route.path.toLowerCase();
        if (element.path && element.path.toLowerCase() == path) {
          _this.buttonList = element.children;
          return;
        } else if (element.children) {
          _this.getButtonList(element.children);
        }
      });
    },
    formatStartTime: function (row, colume) {
      var date = row[colume.property];
      if (date == undefined) {
        return "";
      }
      return util.formatDate.format(new Date(date), "yyyy-MM-dd");
    },
    formatEndTime: function (row, colume) {
      var date = row[colume.property];
      if (date == undefined) {
        return "";
      }
      return util.formatDate.format(new Date(date), "yyyy-MM");
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.page = val;
      this.getData(false);
    },
    usercallFunction(newdata) {
      this.PermissionForm.Post = newdata.Name;
      this.PermissionForm.PostId = newdata.Id;
      this.addUserVisible = false;
    },

    chooseUser() {
      this.addUserVisible = true;
    },

    handleShow() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要编辑的一行数据！",
          type: "error",
        });
        return;
      }
      getInfoList().then((res) => {
        this.SalaryTypeLists = res.data.response;
      });
      GetUserDis().then((res) => {
        this.PositionLists = res.data.response;
      });
      getGetPositiveDetail({ id: row.Id }).then((res) => {
        console.log(res);
        this.PermissionForm = res.data.response;
        this.key += 1;
        this.permissionShowVisible = true;
      });

      this.operation = false;
    },
    handleExamine() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要审核的一行数据！",
          type: "error",
        });
        return;
      }
      this.$api.PositiveSaveState({id:row.Id}).then((res) => {
         if (res.data.success) {
              this.$message({
                message: "审核成功",
                type: "success",
                duration: 5000,
              });
              this.permissionShowVisible = false;
            } else {
              this.$message({
                message: res.data.msg,
                type: "error",
                duration: 5000,
              });
            }
      });
     
      },
      // 删除
      handleDelete() {
          let row = this.currentRow;

          if (!row) {
              this.$message({
                  message: "请选择要删除的一行数据！",
                  type: "error",
              });
              return;
          }

          this.$confirm("确定要删除吗？", "提示", {}).then(() => {
              let para = {
                  Id: row.Id,
              };
              DeletePositiveInfo(para).then((res) => {
                  if (res.data.success) {
                      this.$message({
                          message: "删除成功",
                          type: "success",
                          duration: 5000,
                      });
                      this.getData(false);
                  } else {
                      this.$message({
                          message: "删除失败",
                          type: "error",
                          duration: 5000,
                      });
                  }
              });
          });
      },
    onPermissionSubmit(formName) {
      this.$confirm("确定要提交吗？", "提示", {}).then(() => {
        this.$refs[formName].validate((valid) => {
          let para = Object.assign({}, this.PermissionForm);
          console.log(para);
          UpdatePositive(para).then((res) => {
            console.log(res);
            if (res.data.success) {
              this.$message({
                message: "添加成功",
                type: "success",
                duration: 5000,
              });
              this.permissionShowVisible = false;
            } else {
              this.$message({
                message: res.data.msg,
                type: "error",
                duration: 5000,
              });
            }
          });
          this.getData();
        });
      });
    },
    PositionModel(id) {
      var obj = {};
      obj = this.PositionLists.find((item) => {
        //model就是上面的数据源
        console.log(item);
        return item.LeaveName === id; //筛选出匹配数据
      });
      this.PermissionForm.PositionId = obj.Id;
    },
    SalaryTypeModel(id) {
      var obj = {};
      obj = this.SalaryTypeLists.find((item) => {
        //model就是上面的数据源
        return item.Type === id; //筛选出匹配数据
      });

      this.PermissionForm.SalaryTypeId = obj.Id;
    },
    dealFiles(data) {
      console.log(data.backData);
      this.PermissionForm.FileArry = data.backData;
      // this.fileList = data.backData;
      // console.log('dealFiles-list:' + this.fileList);
    },
  },
  mounted() {
    this.getData();
    let routers = window.localStorage.router
      ? JSON.parse(window.localStorage.router)
      : [];
    let buttons = window.localStorage.buttList
      ? JSON.parse(window.localStorage.buttList)
      : [];
    this.buttonList = getButtonList(this.$route.path, routers, buttons);
  },
};
</script>
<style scoped>
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}
</style>

